.container {
  --sidebar-width: 220px;
  display: flex;
  flex-direction: column;
  border-radius: var(--sizing-border-radius);
  height: 100%;
}

.container h3 {
  width: 100%;
  background-color: var(--color-neutral-1);
  padding: 0.75rem 1rem;
  font-weight: bold;
  font-size: var(--sizing-font-normal);
}

.heading {
  font-size: var(--sizing-font-medium);
  font-weight: bold;
  padding: 2rem 1rem 0rem 2rem;
}

.body {
  display: flex;
  position: relative;
  flex-grow: 1;
  background-color: var(--color-neutral-3);
}

.sidebar {
  height: 100%;
  min-width: var(--sidebar-width);
  z-index: 1;
}

.gradient {
  --color-gradient-rgb: 0, 187, 228;
  background: linear-gradient(
    to bottom,
    rgba(var(--color-gradient-rgb), 0.12) 0%,
    rgba(var(--color-neutral-3-rgb), 1) 70%
  );
  height: 140px;
  width: calc(100%);
  pointer-events: none;
  z-index: 0;
  position: absolute;
}

.section {
  width: 100%;
  z-index: 1;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  gap: 3.25rem;
  position: relative;
}

.prodLineSelectContainer > [role='listbox'] {
  position: relative;
}

.prodLineSelectContainer {
  width: max-content;
}

.prodLineSelectContainer ul {
  max-height: calc(90vh - 15rem);
  overflow-y: scroll;
}

.noData {
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-5);
}
