.stepsContainer {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 1rem;
  position: relative;
}

.formInputContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  white-space: normal;
}

.stepBarContainer {
  --step-card-width: 15rem;
  --icon-size: 2.5rem;
  --box-width: 16.5rem;
  --nav-bar-height: 3rem;
  --gap-between-step-boxes: 1rem;

  display: flex;
  width: fit-content;
  align-items: center;

  margin-top: 3rem;
  margin-left: calc(
    1rem + calc(var(--step-card-width) / 2) - calc(var(--icon-size) / 2)
  );

  transition: width 250ms;
  height: var(--nav-bar-height);

  padding-right: 8rem;
}

.stepIcon {
  display: flex;
}

.stepBarBox h1 {
  margin-bottom: 1rem;
}

.tooltipClass {
  background: transparent;
}

.iconContainer {
  min-height: 3rem;
  display: flex;
  align-items: center;
}

.stepBarIconWrapper {
  --icon-border-color: var(--color-primary-1);
  --icon-background-color: transparent;
  --line-color: var(--color-primary-1);

  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  --font-color: var(--color-text-4);
  --font-size: var(--sizing-font-normal);

  --active-step-margin: 1.625rem;
  --line-height: 0.125rem;
  --line-margin: 0.25rem;
  --line-width: calc(calc(var(--gap) - var(--line-margin) * 2));
  --active-line-width: calc(var(--line-width) + var(--active-step-margin));
}
.stepBarIconWrapper[data-active='true'] {
  --icon-border-color: var(--color-primary-1);
  --font-color: var(--color-text-2);
  --font-size: var(--sizing-font-medium);
}
.stepBarIconWrapper[data-is-completed='true'] {
  --icon-border-color: var(--color-primary-1);
  --icon-background-color: var(--color-primary-1);
  --font-color: var(--color-neutral-1);
}

.stepBarIconWrapper[data-active='true'] {
  --icon-size: 3rem;
}

.stepBarIconWrapper:not(:first-of-type) {
  --gap: calc(
    var(--step-card-width) - var(--icon-size) + var(--gap-between-step-boxes)
  );
  margin-left: var(--gap);
  position: relative;
}

/* The progress line between the steps */

.stepBarIconWrapper:not(:first-of-type)::before {
  content: '';
  position: absolute;
  top: calc(50% - var(--line-height));
  right: calc(100% + var(--line-margin));
  width: calc(var(--line-width));
  height: var(--line-height);
  background-color: var(--line-color);
  border-radius: 1rem;
}

/* Margin change of step boxes based on if they are active */

.stepBarIconWrapper[data-active='true']:first-of-type {
  margin-left: var(--active-step-margin);
}

.stepBarIconWrapper[data-active='true']:not(:first-of-type) {
  margin-left: calc(var(--active-line-width) + var(--gap-between-step-boxes));
}
.stepBarIconWrapper[data-previous-active='true']:not(:first-of-type) {
  margin-left: calc(var(--active-line-width) + 8px);
}

/* Navigation line width change based on active step */

.stepBarIconWrapper[data-active='true']:not(:first-of-type)::before,
.stepBarIconWrapper[data-previous-active='true']:not(:first-of-type)::before {
  width: 234px;
}

.stepBarIconWrapper[data-is-button='true']:last-child {
  --line-color: var(--color-neutral-7);
  --icon-border-color: var(--color-tertiary);
  cursor: pointer;
}

.stepBarIconWrapper[data-is-button='true']:last-child:hover {
  --icon-border-color: var(--color-text-4);
}
.stepBarIconWrapper[data-is-button='true']:last-child:hover svg * {
  fill: var(--color-text-2);
}

.stepBarIconWrapper[data-is-disabled='true'] {
  --icon-border-color: var(--color-primary-4);
  --icon-background-color: var(--color-primary-4);
  --font-color: var(--color-neutral-4);
  --line-color: var(--color-primary-4);
  pointer-events: none;
}

.stepBarIcon {
  color: var(--font-color);
  font-weight: bold;
  font-size: var(--font-size);
  cursor: pointer;
  width: var(--icon-size);
  height: var(--icon-size);
  background-color: var(--icon-background-color);
  display: grid;
  place-content: center;
  border-radius: 50%;
  border: 2px solid var(--icon-border-color);

  transition: width 250ms, height 250ms, font-size 250ms;
}

.stepCardContainer {
  display: flex;
  flex-direction: column;
}

.stepCard {
  --card-width: 240px;
  background-color: var(--color-neutral-5);
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  gap: 0.75rem;
  width: var(--card-width);
  border-radius: 4px;

  transition: width 250ms;
}
.stepCard[data-active='false'] {
  cursor: pointer;
}
.stepCard[data-error='true'],
.stepCard[data-active='false'][data-is-complete='false'] {
  border: 1px solid var(--color-error);
  transition: border 500ms;
}

.stepCard[data-active='true'] {
  --card-width: 300px;
}

.logisticsStep {
  position: absolute;
  top: calc(var(--nav-bar-height) + 0.75rem);
}

.logisticsStep[data-disabled='true'] * {
  pointer-events: none;
  color: var(--color-text-5);
}

.cardSection {
  --border-color: var(--color-tertiary);
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  width: 100%;
}

.cardSection:last-child {
  margin-top: 0.75rem;
}

.cardSection:hover {
  --border-color: var(--color-text-4);
  cursor: pointer;
}

/* Adding padding to the radio button component to make whole div clickable */
.cardSection[data-active='false'] > label {
  padding: 0.75rem;
  height: 100%;
}

.cardSection[data-active='true'] {
  --border-color: var(--color-primary-1);
  padding: 0.75rem;
}
.cardBody {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 0.75rem;
}

.removeStepButton {
  min-width: 2rem;
  height: 2rem;
  border-radius: 0.25rem;
  border: none;
  display: grid;
  place-content: center;
  background-color: transparent;
  cursor: pointer;
}
.removeStepButton:hover {
  background-color: var(--color-secondary-1);
}

.stepForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.divider {
  height: 1px;
  width: 100%;
  border-radius: 2px;
  background-color: var(--color-neutral-6);
  display: inline-block;
}
.stepName {
  font-size: var(--sizing-font-normal);
  color: var(--color-text-2);
  font-weight: bold;
}
