.languagePickerButtonText {
  max-width: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
}

.buttonContainer {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.languageContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  padding: 0 0.75rem 0.75rem;
  color: var(--color-text-4);
  font-size: var(--sizing-font-small);
}
