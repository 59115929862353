.site-overview_container__YlX64 {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.site-overview_gradient__k7akN {
  --color-gradient-rgb: 0, 187, 228;

  background: linear-gradient(
    to bottom,
    rgba(var(--color-gradient-rgb), 0.15) 0%,
    rgba(var(--color-neutral-3-rgb), 1) 100%
  );
  height: 140px;
  width: 100%;
  position: absolute;
  pointer-events: none;
  z-index: -1;
}
.site-overview_header__HGWDr {
  height: 6rem;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
}

.site-overview_siteDropdown__Er_R7 {
  display: flex;
  gap: var(--sizing-medium);
  height: 2rem;
  align-items: center;
  color: var(--color-text-4);
  font-size: var(--sizing-font-small);
}

.site-overview_siteDropdown__Er_R7 ul {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 50vh;
}

.site-overview_section__oGbBD {
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: var(--sizing-normal);
}

.site-overview_sectionTop__8aawO {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-overview_h1__gzu6N {
  font-size: var(--sizing-font-large);
}

.site-overview_h2__CJ_pX {
  font-size: var(--sizing-font-medium);
  font-weight: bold;
}

.site-overview_batchName__wDcxO {
  display: flex;
  gap: var(--sizing-medium);
  align-items: center;
}

.site-overview_batchColor__lil9w {
  height: var(--sizing-medium);
  width: var(--sizing-medium);
  border-radius: 50%;
  display: inline-block;
  background: var(--dark-graph-graph-3, #00beeb);
  min-width: 0.75rem;
}

.site-overview_sectionTable__4D9Ts {
  min-height: 2rem;
  text-align: left;
  background-color: var(--color-neutral-5);
  border-radius: 0.5rem;
  border-collapse: collapse;
}

/* Last tbody in the first table */
.site-overview_sectionTable__4D9Ts:first-of-type tbody:last-of-type {
  display: block;
  border-radius: 0 0 0.5rem 0.5rem;
}

.site-overview_sectionTable__4D9Ts:not(:first-child) {
  border-bottom: hidden;
}

.site-overview_sectionTable__4D9Ts tbody:not(:last-child) {
  border-bottom: 0.5rem solid transparent;
  border-top: 0.5rem solid transparent;
}


#site-overview_prodTable__1qwbi tbody:nth-child(even) {  
  background: var(--dark-neutrals-neutral-4, #26323c);    
}

/* row */
.site-overview_sectionTable__4D9Ts tr {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  gap: var(--sizing-large);
  overflow-wrap: break-word;
  overflow: hidden;
  justify-content: flex-end;
}

/* header row */
.site-overview_sectionTable__4D9Ts thead {
  min-height: 2.75rem;
  color: var(--color-text-4);
  font-size: var(--sizing-font-small);
  position: relative;
}

.site-overview_sectionTable__4D9Ts thead::after {
  content: '';
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--color-neutral-6);
}

.site-overview_sectionTable__4D9Ts th {
  font-weight: 400;
}

/* cells */
.site-overview_sectionTable__4D9Ts td,
.site-overview_sectionTable__4D9Ts th {
  line-height: 1rem;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.site-overview_sectionTable__4D9Ts td:not(:last-child),
.site-overview_sectionTable__4D9Ts th:not(:last-child) {
  flex-basis: 125px;
}

/* first cell */
.site-overview_sectionTable__4D9Ts td:first-child,
.site-overview_sectionTable__4D9Ts th:first-child {
  min-width: 20%;
  flex-grow: 1;
  gap: 0.75rem;
}

.site-overview_sectionTable__4D9Ts th[data-align='left'],
.site-overview_sectionTable__4D9Ts td[data-align='left'] {
  justify-content: flex-start;
}

.site-overview_sectionTable__4D9Ts th[data-align='center'],
.site-overview_sectionTable__4D9Ts td[data-align='center'] {
  justify-content: center;
}

.site-overview_sectionTable__4D9Ts th[data-align='right'],
.site-overview_sectionTable__4D9Ts td[data-align='right'] {
  justify-content: flex-end;
}

.site-overview_sectionTable__4D9Ts td:last-child,
.site-overview_sectionTable__4D9Ts th:last-child {
  width: 4rem;
}

.site-overview_sectionTable__4D9Ts td[data-type='no-data'] {
  height: 140px;
  justify-content: center;
  color: var(--color-text-5);
}

.site-overview_sectionTable__4D9Ts h3 {
  font-weight: bold;
  color: var(--color-text-2);
  font-size: var(--sizing-font-normal);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

.site-overview_flexAlignRight__Vx3OW {
  justify-content: flex-end;
}

.site-overview_secondBatchTableBackgroundColor__47f_P {
  background: var(--dark-neutrals-neutral-4, #26323c);
}

.site-overview_hidden__8aWrM {
  visibility: hidden;
}

.site-overview_productionTarget__94c42 {
  padding-left: 3rem;
}

.steps-sidebar_sidebar__0zOPU {
  --circle-radius: 8px;
  --line-width: 2px;
  --line-height: 2rem;
  --border-color: var(--color-neutral-7);
  --border-color-active: var(--color-primary-1);
  --background-color: var(--color-primary-1);
  --padding-y: 2rem;
  --padding-x: 1rem;
  --line-margin: 2px;
  --spacing: 1.25rem;
  --steps-gap: 5rem;

  background-color: var(--color-neutral-2);
  padding: 2rem 1rem 1rem;

  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto max-content max-content;
  grid-auto-flow: row;
  word-break: break-all;
  min-width: var(--sidebar-width);
}

.steps-sidebar_sidebar__0zOPU ul {
  list-style: none;
}

.steps-sidebar_sidebar__0zOPU li {
  white-space: nowrap;
}

.steps-sidebar_sections__1VgJV {
  display: flex;
  flex-direction: column;
  gap: var(--steps-gap);
}

.steps-sidebar_sections__1VgJV li {
  position: relative;
  margin: 0;
  height: 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.steps-sidebar_sections__1VgJV li[data-visited='false'] {
  color: var(--color-text-5);
}
.steps-sidebar_sections__1VgJV li[data-active='true'] {
  font-weight: bold;
  color: var(--color-text-2);
}

.steps-sidebar_sections__1VgJV li[data-visited='true'] {
  cursor: pointer;
  color: var(--color-text-4);
}

.steps-sidebar_sections__1VgJV li[data-completed='true']:hover {
  color: var(--color-text-2);
}

/* The before element is the line connecting the circles. */
.steps-sidebar_sections__1VgJV li::before {
  background-color: var(--border-color);
  width: var(--line-width);
  border-radius: var(--line-width);
  height: var(--steps-gap);
  left: 0;
  top: 0;
  content: '';
  position: absolute;
  transform: translate(calc(var(--circle-radius) - 50%), calc(var(--spacing)));
}
.steps-sidebar_sections__1VgJV li:last-child::before {
  display: none;
}

.steps-sidebar_sidebar__0zOPU li[data-completed='true']::before {
  background-color: var(--border-color-active);
}

.steps-sidebar_sectionsCircle__3tS9v {
  border-radius: 100%;
  background-color: transparent;
  border: var(--line-width) solid var(--border-color);
  width: calc(2 * var(--circle-radius));
  height: calc(2 * var(--circle-radius));
  display: grid;
  place-content: center;
  box-sizing: border-box;
}

.steps-sidebar_sections__1VgJV li[data-completed='true'] .steps-sidebar_sectionsCircle__3tS9v {
  background-color: var(--background-color);
  border-color: var(--border-color-active);
}

.steps-sidebar_sections__1VgJV li[data-active='true'][data-completed='false'] .steps-sidebar_sectionsCircle__3tS9v {
  background-color: transparent;
  border-color: var(--border-color-active);
}

.steps-sidebar_bottomContainer__SYEJ8 {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.steps-sidebar_sidebarInfo__TJ7JU {
  display: flex;
  flex-direction: column;
  gap: var(--sizing-tiny);
}

.steps-sidebar_sidebarInfo__TJ7JU dt {
  color: var(--color-text-4);
  font-size: var(--sizing-font-small);
}

.steps-sidebar_sidebarInfo__TJ7JU dd {
  color: var(--color-text-2);
  font-size: var(--sizing-font-normal);
  word-break: break-word;
}

.steps-sidebar_cancelButton__ZIfup {
  width: 100%;
}

.buttons-row_buttonsRow__Tr3d0 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
}

.buttons-row_hidden__GIlyD {
  visibility: hidden;
}
.information-row_informationRow___bAkE {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  text-align: right;
}

.information-row_label__svAHO {
  color: var(--color-text-4);
  font-size: 14px;
}
.information-row_value__bB86H {
  color: var(--color-text-2);
  font-size: 14px;
  font-weight: bold;
  cursor: text;
  pointer-events: none;
}

.shared_errorMessageContainer__Syzwp {
  display: flex;
  height: max-content;
  gap: 0.25rem;
  margin-top: 0.25rem;
}

.shared_errorMessage__IaZdK {
  font-size: var(--sizing-font-small);
  color: var(--color-text-2);
  font-weight: normal;
  height: 100%;
}

.container_container__jkaWJ {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0rem 1rem 1rem 2rem;
  width: 100%;
  position: relative;
}

.deliveries_deliveryContainer__VjiKf {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.deliveries_deliverySection__OIeb5 {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 2rem;
}

.deliveries_deliveryInputRow__NFTNR {
  display: flex;
  gap: 4rem;
  width: -moz-fit-content;
  width: fit-content;
}
.deliveries_deliveryInputSection__7068V {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 280px;
}

.deliveries_deliveryBody__N_OZt {
  display: flex;
  gap: 2rem;
  overflow-x: auto;
  padding-right: 8rem;
  height: 100%;
  padding-bottom: 1rem;
}
.deliveries_deliveryBody__N_OZt[data-mode='delivery'] {
  min-height: -moz-fit-content;
  min-height: fit-content;
  margin-bottom: 10px;
}

.deliveries_deliveryItem__otc4s {
  flex: 0 0 auto; /* This ensures that the items do not shrink */
  height: -moz-fit-content;
  height: fit-content;
}

.deliveries_salesDelivery__3FfTu {
  margin-top: auto;
  margin-bottom: 2rem;
}
.deliveries_informationTable__RSX45 {
  border: 1px solid var(--color-neutral-6);
  border-radius: 4px;
  display: flex;
  gap: 1.5rem;
  padding: var(--sizing-normal);
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.deliveries_informationCellContainer__E2ZYu {
  display: flex;
  gap: 1.5rem;
}

.deliveries_informationCell__6tQ8_ {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.deliveries_verticalDivider___OtPF {
  width: 1px;
  height: 100%;
  border-radius: 2px;
  background-color: var(--color-neutral-6);
  display: inline-block;
}

.deliveries_informationValue__ElIAJ {
  line-height: 28px;
  font-size: var(--sizing-font-medium);
  color: var(--color-text-2);
  font-weight: bold;
}
.deliveries_informationLabel__aa_3v {
  line-height: 20px;
  font-size: var(--sizing-font-small);
  color: var(--color-text-4);
}
.deliveries_calendarLabel__2cklR {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deliveries_stepSummary___kcOq {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
}

.deliveries_stepHeader__m6aDD {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  width: 100%;
  height: 2rem;
}
.deliveries_stepNameContainer__jc2UJ {
  display: flex;
  gap: 0.5rem;

  align-items: center;
}
.deliveries_stepNumber__UrFIl {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  color: var(--color-text-2);
  font-size: var(--sizing-font-xsmall);
}

.deliveries_stepName__BpUZQ {
  font-size: var(--sizing-font-normal);
  color: var(--color-text-2);
  font-weight: bold;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline;
}

.deliveries_removeStepButton__G3kB4 {
  min-width: 2rem;
  height: 2rem;
  border-radius: 0.25rem;
  border: none;
  display: grid;
  place-content: center;
  background-color: transparent;
  cursor: pointer;
}
.deliveries_removeStepButton__G3kB4:hover {
  background-color: var(--color-secondary-1);
}

.deliveries_wholeBatch__YKYkK {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.deliveries_wholeBatchAmount__Y_yUo {
  color: var(--color-text-4);
}
.deliveries_wholeBatchAmount__Y_yUo[data-disabled='true'] {
  color: var(--color-text-5);
}

.sections_stepsContainer__D_QZQ {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 1rem;
  position: relative;
}

.sections_formInputContainer__CYPti {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  white-space: normal;
}

.sections_stepBarContainer__w29Qm {
  --step-card-width: 15rem;
  --icon-size: 2.5rem;
  --box-width: 16.5rem;
  --nav-bar-height: 3rem;
  --gap-between-step-boxes: 1rem;

  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;

  margin-top: 3rem;
  margin-left: calc(
    1rem + calc(var(--step-card-width) / 2) - calc(var(--icon-size) / 2)
  );

  transition: width 250ms;
  height: var(--nav-bar-height);

  padding-right: 8rem;
}

.sections_stepIcon__k3N6n {
  display: flex;
}

.sections_stepBarBox__XrIW0 h1 {
  margin-bottom: 1rem;
}

.sections_tooltipClass__epe5i {
  background: transparent;
}

.sections_iconContainer__XfsVE {
  min-height: 3rem;
  display: flex;
  align-items: center;
}

.sections_stepBarIconWrapper__6vDmi {
  --icon-border-color: var(--color-primary-1);
  --icon-background-color: transparent;
  --line-color: var(--color-primary-1);

  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  --font-color: var(--color-text-4);
  --font-size: var(--sizing-font-normal);

  --active-step-margin: 1.625rem;
  --line-height: 0.125rem;
  --line-margin: 0.25rem;
  --line-width: calc(calc(var(--gap) - var(--line-margin) * 2));
  --active-line-width: calc(var(--line-width) + var(--active-step-margin));
}
.sections_stepBarIconWrapper__6vDmi[data-active='true'] {
  --icon-border-color: var(--color-primary-1);
  --font-color: var(--color-text-2);
  --font-size: var(--sizing-font-medium);
}
.sections_stepBarIconWrapper__6vDmi[data-is-completed='true'] {
  --icon-border-color: var(--color-primary-1);
  --icon-background-color: var(--color-primary-1);
  --font-color: var(--color-neutral-1);
}

.sections_stepBarIconWrapper__6vDmi[data-active='true'] {
  --icon-size: 3rem;
}

.sections_stepBarIconWrapper__6vDmi:not(:first-of-type) {
  --gap: calc(
    var(--step-card-width) - var(--icon-size) + var(--gap-between-step-boxes)
  );
  margin-left: var(--gap);
  position: relative;
}

/* The progress line between the steps */

.sections_stepBarIconWrapper__6vDmi:not(:first-of-type)::before {
  content: '';
  position: absolute;
  top: calc(50% - var(--line-height));
  right: calc(100% + var(--line-margin));
  width: calc(var(--line-width));
  height: var(--line-height);
  background-color: var(--line-color);
  border-radius: 1rem;
}

/* Margin change of step boxes based on if they are active */

.sections_stepBarIconWrapper__6vDmi[data-active='true']:first-of-type {
  margin-left: var(--active-step-margin);
}

.sections_stepBarIconWrapper__6vDmi[data-active='true']:not(:first-of-type) {
  margin-left: calc(var(--active-line-width) + var(--gap-between-step-boxes));
}
.sections_stepBarIconWrapper__6vDmi[data-previous-active='true']:not(:first-of-type) {
  margin-left: calc(var(--active-line-width) + 8px);
}

/* Navigation line width change based on active step */

.sections_stepBarIconWrapper__6vDmi[data-active='true']:not(:first-of-type)::before,
.sections_stepBarIconWrapper__6vDmi[data-previous-active='true']:not(:first-of-type)::before {
  width: 234px;
}

.sections_stepBarIconWrapper__6vDmi[data-is-button='true']:last-child {
  --line-color: var(--color-neutral-7);
  --icon-border-color: var(--color-tertiary);
  cursor: pointer;
}

.sections_stepBarIconWrapper__6vDmi[data-is-button='true']:last-child:hover {
  --icon-border-color: var(--color-text-4);
}
.sections_stepBarIconWrapper__6vDmi[data-is-button='true']:last-child:hover svg * {
  fill: var(--color-text-2);
}

.sections_stepBarIconWrapper__6vDmi[data-is-disabled='true'] {
  --icon-border-color: var(--color-primary-4);
  --icon-background-color: var(--color-primary-4);
  --font-color: var(--color-neutral-4);
  --line-color: var(--color-primary-4);
  pointer-events: none;
}

.sections_stepBarIcon__TncbW {
  color: var(--font-color);
  font-weight: bold;
  font-size: var(--font-size);
  cursor: pointer;
  width: var(--icon-size);
  height: var(--icon-size);
  background-color: var(--icon-background-color);
  display: grid;
  place-content: center;
  border-radius: 50%;
  border: 2px solid var(--icon-border-color);

  transition: width 250ms, height 250ms, font-size 250ms;
}

.sections_stepCardContainer__Pw62y {
  display: flex;
  flex-direction: column;
}

.sections_stepCard__wI0ht {
  --card-width: 240px;
  background-color: var(--color-neutral-5);
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  gap: 0.75rem;
  width: var(--card-width);
  border-radius: 4px;

  transition: width 250ms;
}
.sections_stepCard__wI0ht[data-active='false'] {
  cursor: pointer;
}
.sections_stepCard__wI0ht[data-error='true'],
.sections_stepCard__wI0ht[data-active='false'][data-is-complete='false'] {
  border: 1px solid var(--color-error);
  transition: border 500ms;
}

.sections_stepCard__wI0ht[data-active='true'] {
  --card-width: 300px;
}

.sections_logisticsStep__xZntK {
  position: absolute;
  top: calc(var(--nav-bar-height) + 0.75rem);
}

.sections_logisticsStep__xZntK[data-disabled='true'] * {
  pointer-events: none;
  color: var(--color-text-5);
}

.sections_cardSection__QLV3r {
  --border-color: var(--color-tertiary);
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  width: 100%;
}

.sections_cardSection__QLV3r:last-child {
  margin-top: 0.75rem;
}

.sections_cardSection__QLV3r:hover {
  --border-color: var(--color-text-4);
  cursor: pointer;
}

/* Adding padding to the radio button component to make whole div clickable */
.sections_cardSection__QLV3r[data-active='false'] > label {
  padding: 0.75rem;
  height: 100%;
}

.sections_cardSection__QLV3r[data-active='true'] {
  --border-color: var(--color-primary-1);
  padding: 0.75rem;
}
.sections_cardBody__WnQGw {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 0.75rem;
}

.sections_removeStepButton__ks0bD {
  min-width: 2rem;
  height: 2rem;
  border-radius: 0.25rem;
  border: none;
  display: grid;
  place-content: center;
  background-color: transparent;
  cursor: pointer;
}
.sections_removeStepButton__ks0bD:hover {
  background-color: var(--color-secondary-1);
}

.sections_stepForm__hMEL8 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.sections_divider__8_uEi {
  height: 1px;
  width: 100%;
  border-radius: 2px;
  background-color: var(--color-neutral-6);
  display: inline-block;
}
.sections_stepName__ol5nu {
  font-size: var(--sizing-font-normal);
  color: var(--color-text-2);
  font-weight: bold;
}

.preview_form__Ip5ju {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.preview_formInputs__otOZZ {
  max-width: 24rem;
  display: flex;
  flex-direction: column;
  gap: var(--sizing-medium);
}

.preview_formInputs__otOZZ label {
  min-width: 8rem;
}

.preview_formSelect__iF6QM {
  display: flex;
  gap: var(--sizing-tiny);
}

.preview_formSelect__iF6QM > div {
  width: 100%;
}

.production-line_container__oyOZ7 {
  --sidebar-width: 220px;
  display: flex;
  flex-direction: column;
  border-radius: var(--sizing-border-radius);
  overflow: hidden;
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.production-line_header__7x4hE {
  height: 3rem;
  width: 100%;
  background-color: var(--color-neutral-1);
  padding: 0.75rem 1rem;
}
.production-line_header__7x4hE label {
  font-weight: bold;
  font-size: var(--sizing-font-normal);
}

.production-line_heading__Q_uuN {
  font-size: var(--sizing-font-medium);
  font-weight: bold;
  padding: 2rem 2rem 0;
}

.production-line_body__s7bj1 {
  display: flex;
  background-color: var(--color-neutral-3);
  position: relative;
  flex-grow: 1;
}
.production-line_sidebar__xZvoT {
  height: 100%;
  width: var(--sidebar-width);
  z-index: 1;
}

.production-line_gradient__z__93 {
  --color-gradient-rgb: 0, 187, 228;
  background: linear-gradient(
    to bottom,
    rgba(var(--color-gradient-rgb), 0.12) 0%,
    rgba(var(--color-neutral-3-rgb), 1) 70%
  );
  height: 140px;
  width: calc(100%);
  pointer-events: none;
  z-index: 0;
  position: absolute;
}

.production-line_section__IYqDO {
  width: 100%;
  overflow-y: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.step-summary_stepSummary__kg_5p {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.step-summary_textField__FzA96 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  width: 100%;
  height: 2rem;
}

.step-summary_stepName__v_apI {
  font-size: var(--sizing-font-normal);
  color: var(--color-text-2);
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline;
}

.step-summary_removeStepButton__bSNbf {
  min-width: 2rem;
  height: 2rem;
  border-radius: 0.25rem;
  border: none;
  display: grid;
  place-content: center;
  background-color: transparent;
  cursor: pointer;
}
.step-summary_removeStepButton__bSNbf:hover {
  background-color: var(--color-secondary-1);
}

.step-summary_stepInformationContainer__QyAep {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.25rem
}

.step-summary_parallelName___5lHn {
  font-size: var(--sizing-font-small);
  color: var(--color-text-2);
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 1.5rem;
}
.step-summary_parallelName___5lHn[data-is-active='true'] {
  height: 2rem; /*  Because of the icon height */
}

.step-summary_parallelPreview__bgdgv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 4px;
  background: rgba(var(--color-white-rgb), 0.06);
  border-radius: 0.25rem;
  width: 100%;
}

.step-summary_parallelPreview__bgdgv[data-is-active='true'] {
  gap: 8px;
}

.conditions_container__KIJ8C {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.conditions_sortingStepContainer__PjwOC {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.conditions_label__NQm5g {
  color: var(--color-text-4);
  font-size: 14px;
}

.conditions_stepInformationContainer__vCMjI {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.conditions_informationContainer__pqve2 {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

.conditions_degreeDaysSummary__i61Oi {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.conditions_stepButtonsRow__92VCl {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.conditions_sortingHeader__Kdr2N {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.conditions_sortingBadge__TlD4L {
  height: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 2px 8px;
  border-radius: 100px;
  background-color: var(--color-neutral-1);

  color: var(--color-text-4);
  font-size: var(--sizing-font-small);
}

.conditions_sortingPreview__27xDl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 4px;
  background: rgba(var(--color-white-rgb), 0.06);
  border-radius: 0.25rem;
  width: 100%;
}
.conditions_weightClassName__A7Rzp {
  font-size: var(--sizing-font-small);
  color: var(--color-text-2);
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  width: 100%;
  min-height: 2rem;
}

.conditions_sortingPreview__27xDl[data-is-active='true'] {
  gap: 8px;
}

.conditions_sortingWeightClasses__411qX {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.conditions_departmentsMultiselect__TzcTq {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

.conditions_keepSortingMultiSelect__XWE_v {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 50%;
}

.conditions_buttonSpan__bAkps > button {
  width: 100%;
}

.conditions_sortingRow___Wb_0 {
  display: flex;
  width: 100%;
  gap: 4px;
}

.conditions_departmentsMultiselect__TzcTq {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.conditions_buttonsContainer__aEcQw {
  padding: 1rem 1rem 1rem 2rem;
}

.multiselect_container__5fl5V {
  color: var(--color-text-2);
  display: inline-block;
  position: relative;
  padding: 0;
  text-align: left;
  height: 2rem;
  margin-bottom: 0.5rem;
}

.multiselect_icon__ZvAWQ {
  display: flex;
  justify-content: center;
}
.multiselect_iconButton__lgjMg {
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  margin-left: 0.75rem;
}
.multiselect_iconButton__lgjMg:hover {
  background-color: rgba(var(--color-white-rgb), 0.06);
}
.multiselect_button__aiAn3 {
  background: var(--color-neutral-2);

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.25rem 0.375rem 0.25rem 0.5rem;
  outline: none;
  transition: all 200ms;
  border-radius: 0.25rem;
  border: 1px solid var(--color-neutral-6);
  width: 100%;
  height: 2rem;
}

.multiselect_button__aiAn3[data-active='false']:enabled:hover {
  cursor: pointer;
  border-color: var(--color-neutral-7);
}

.multiselect_button__aiAn3[data-active='true'] {
  border: 1px solid var(--color-primary-1);
  color: var(--color-text-2);
}

.multiselect_labelText__oxGNk {
  height: 100%;
  text-align: left;
  font-size: var(--sizing-font-normal);
  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Added this */
  max-width: 100%; /* Ensures it takes up to 100% of parent width */

  color: var(--color-text-2);

  /* Disabling pointer-events for the checkbox component, because
  otherwise onStateChange event gets triggered twice and as a 
  result selected items always remain unselected. */
  pointer-events: none;
}

.multiselect_labelText__oxGNk[data-disabled='true'] {
  color: var(--color-text-5);
}

.multiselect_dropdown__sk49y {
  border-radius: 0.25rem;
  background: var(--color-neutral-2);

  list-style-type: none;

  overflow-y: auto;
  overflow-x: hidden;
  min-width: max(100%, 12rem);
  max-width: 20rem;

  max-height: 55vh;

  /* Use really high zIndex to ensure on top */
  z-index: 9999;
  padding-top: 0.25rem;
  margin-top: 2px;
}
.multiselect_dropdown__sk49y ul {
  min-width: 100%;
}

.multiselect_item__EiVU_ {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0.25rem 0.25rem;
  border-radius: 0.25rem;
  flex-direction: column;
}

.multiselect_departmentRow__EZcvP {
  display: flex;
  flex-direction: column;

  /* Gap between each row */
  margin-bottom: 0.25rem;
  border-radius: 4px;
  padding: 0.5rem;
}
.multiselect_departmentRow__EZcvP[data-is-disabled='false']:hover,
.multiselect_departmentRow__EZcvP[data-highlighted-index='true'] {
  background-color: var(--color-neutral-4);
}

.multiselect_departmentContent__qsDfb {
  font-size: 1rem;
  align-items: center;
  justify-items: center;
  display: flex;
  gap: 0.75rem;
}

/* Changing the color of the checkbox border on hovering the department row */
.multiselect_departmentRow__EZcvP[data-expand-hovered='false']:hover div > label > span {
  border-color: var(--color-text-4);
  transition: border-color 150ms;
  pointer-events: none;
}

.multiselect_departmentButton__dZwMv {
  display: flex;

  align-items: center;
  justify-content: space-between;

  font-size: 1rem;
  width: 100%;
  height: 100%;
  font-weight: normal;
}
.multiselect_departmentButton__dZwMv span {
  justify-self: start;
}

.multiselect_departmentLabel__sBwSA p {
  transition: color 250ms;
}
.multiselect_departmentLabel__sBwSA *:first-child {
  color: var(--color-text-2);
  text-align: left;
}
.multiselect_departmentLabel__sBwSA[data-is-disabled='true'] p,
.multiselect_departmentVolumeLabel__jTdQG[data-is-disabled='true'] {
  color: var(--color-text-5);
}

.multiselect_departmentVolume__Yh_LI {
  margin-left: auto;
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.multiselect_departmentVolumeLabel__jTdQG {
  font-size: var(--sizing-font-xsmall);
  color: var(--color-text-4);
}
.multiselect_unitsList__V8kQD {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.multiselect_unitRow__D1FTH {
  display: grid;
  grid-template-columns: 1.5rem 1fr max-content;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  align-items: center;

  padding: 8px 8px 8px 32px;
  border-radius: 4px;
  cursor: pointer;
}
.multiselect_unitRow__D1FTH:hover {
  background-color: var(--color-neutral-4);
}

/* Changing the color of the checkbox border on hovering the unit row */
.multiselect_unitRow__D1FTH:hover label > span {
  border-color: var(--color-text-4);
}

.multiselect_unit__Wd6Od span {
  transition: color 250ms;
}
.multiselect_unitVolume__vNgZH {
  font-size: var(--sizing-font-small);
  color: var(--color-text-4);
}
.multiselect_unitDisabled__EnlRY span {
  color: var(--color-text-5);
}

/* Clicking on the checkbox directly closes the dropdown, 
   so disabling any pointer events within it to avoid 
   that */
.multiselect_departmentRow__EZcvP div > label,
.multiselect_unitRow__D1FTH label {
  pointer-events: none;
}

.multiselect_departmentButton__dZwMv[data-is-disabled='true'],
.multiselect_departmentButton__dZwMv[data-is-disabled='true'] *,
.multiselect_unitDisabled__EnlRY {
  color: var(--color-text-5);
  cursor: default;
}

.multiselect_departmentRow__EZcvP[data-is-disabled='true'] {
  cursor: default;
}

.production_selectLabel__20BQl {
  padding-bottom: 0.25rem;
  display: inline-block;
  color: var(--color-text-4);
  font-size: var(--font-sizing-small);
}

.production_stepsContainer__b12qS {
  height: 100%;
  width: 100%;
  padding: 1rem;
  position: relative;
}

.production_stepBarContainer__O01H3 {
  --step-card-width: 15rem;
  --icon-size: 2.5rem;
  --box-width: 16.5rem;
  --nav-bar-height: 3rem;
  --gap-between-step-boxes: 1rem;

  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;

  margin-top: 3rem;
  margin-left: calc(
    1rem + calc(var(--step-card-width) / 2) - calc(var(--icon-size) / 2)
  );

  transition: width 250ms;
  height: var(--nav-bar-height);

  padding-right: 8rem;
}

.production_logisticsStep__PfRrX[data-is-valid='false'] form > div {
  border: 1px solid red;
}

.production_stepButtonsRow__wl4f0 > button {
  width: 100%;
}

.add-batch_container__WDl1f {
  --sidebar-width: 220px;
  display: flex;
  flex-direction: column;
  border-radius: var(--sizing-border-radius);
  height: 100%;
}

.add-batch_container__WDl1f h3 {
  width: 100%;
  background-color: var(--color-neutral-1);
  padding: 0.75rem 1rem;
  font-weight: bold;
  font-size: var(--sizing-font-normal);
}

.add-batch_heading__YCGKs {
  font-size: var(--sizing-font-medium);
  font-weight: bold;
  padding: 2rem 1rem 0rem 2rem;
}

.add-batch_body__rMiZL {
  display: flex;
  position: relative;
  flex-grow: 1;
  background-color: var(--color-neutral-3);
}

.add-batch_sidebar__aCJW9 {
  height: 100%;
  min-width: var(--sidebar-width);
  z-index: 1;
}

.add-batch_gradient__XSoWw {
  --color-gradient-rgb: 0, 187, 228;
  background: linear-gradient(
    to bottom,
    rgba(var(--color-gradient-rgb), 0.12) 0%,
    rgba(var(--color-neutral-3-rgb), 1) 70%
  );
  height: 140px;
  width: calc(100%);
  pointer-events: none;
  z-index: 0;
  position: absolute;
}

.add-batch_section__ygYRn {
  width: 100%;
  z-index: 1;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  gap: 3.25rem;
  position: relative;
}

.add-batch_prodLineSelectContainer__Y_Hd3 > [role='listbox'] {
  position: relative;
}

.add-batch_prodLineSelectContainer__Y_Hd3 {
  width: max-content;
}

.add-batch_prodLineSelectContainer__Y_Hd3 ul {
  max-height: calc(90vh - 15rem);
  overflow-y: scroll;
}

.add-batch_noData___H_H4 {
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-5);
}

@keyframes spinner_spin__EbT_W {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.spinner_spinner__lsAnH {
  --size: 3rem;
  position: fixed;
  top: calc(50% - var(--size) / 2);
  left: calc(50% - var(--size) / 2);
  display: inline-block;
  border: 2px solid var(--color-primary-1);
  border-top: 2px solid var(--color-neutral-5);
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  animation: spinner_spin__EbT_W 1.1s linear infinite;
}

.sale-strategy_growthModelSelectContainer__JLae7 {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.sale-strategy_growthModelSelectContainer__JLae7 > * {
  max-width: 18.75rem;
}

.sale-strategy_growthModelSelectContainer__JLae7 button {
  padding: 0.25rem 0.5rem;
}

/* Select the dropdown menu and make it scrollable */
.sale-strategy_growthModelSelectContainer__JLae7 div div:last-child {
  max-height: 30rem;
  overflow: auto;
}

.sale-strategy_section__SN5Lt {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.sale-strategy_salesStrategies__m_U7e {
  display: flex;
  gap: 1.5rem;
}

.sale-strategy_cardSection__dm34l {
  --border-color: var(--color-tertiary);
  border: 1px solid var(--border-color);
  background-color: var(--color-neutral-4);
  border-radius: 0.25rem;

  width: 284px;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  cursor: pointer;
}
.sale-strategy_cardSection__dm34l:hover {
  --border-color: var(--color-text-4);
  cursor: pointer;
}

.sale-strategy_cardSection__dm34l[data-active='true'] {
  --border-color: var(--color-primary-1);
}
.sale-strategy_icons___2_52 {
  display: flex;
  align-items: center;
  margin-top: auto;
}
.sale-strategy_icons___2_52[data-flex-end='true'] {
  justify-content: flex-end;
}

.sale-strategy_cardSection__dm34l {
  font-size: var(--sizing-font-small);
  line-height: 1.25rem;
}

.sale-strategy_label__tAoYW {
  color: var(--color-text-4);
  word-wrap: break-word;
  white-space: normal;
}

.sale-strategy_setupContainer__EZCRI {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.sale-strategy_setupLabel__759Ed {
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.25rem;
  font-size: var(--sizing-font-small);
  color: var(--color-text-4);
}

.logistics-steps_container__BHuaB {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.logistics-steps_stepForm__nnoHM {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.logistics-steps_label__UiCGo {
  color: var(--color-text-4);
  font-size: 14px;
}

.logistics-steps_textField__Iygkf {
  display: flex;
  gap: 0.75rem;
  width: 100%;
  min-height: 2rem;
  margin-bottom: 0.75rem;
}

.logistics-steps_departmentsMultiselect__eS5ew {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

/* Todo: Remove minimum width property from the library */

.logistics-steps_departmentsMultiselect__eS5ew > div > div > button {
  width: 100% !important;
  min-width: none;
}

.logistics-steps_stepName__KGkW_ {
  --step-label-width: 6rem;
  font-size: var(--sizing-font-normal);
  color: var(--color-text-2);
  font-weight: bold;
  cursor: pointer;
  left: calc(var(--icon-size) / 2 - var(--step-label-width) / 2);
  width: var(--step-label-width);
  text-align: center;
  position: absolute;
  padding: 0.5rem;
  bottom: -2.75rem;
}


.logistics-steps_parallelName__NVcPm {
  font-size: var(--sizing-font-small);
  color: var(--color-text-2);
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 1.5rem;
}
.logistics-steps_parallelName__NVcPm[data-is-active='true'] {
  height: 2rem; /*  Because of the icon height */
}

.logistics-steps_buttonSpan___MaGK > button {
  width: 100%;
}

.logistics-steps_parallelPreview__X0Jif {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 4px;
  background: rgba(var(--color-white-rgb), 0.06);
  border-radius: 0.25rem;
  width: 100%;
}

.logistics-steps_parallelPreview__X0Jif[data-is-active='true'] {
  gap: 8px;
}

.logistics-steps_buttonsContainer__wkUkj {
  padding: 1rem 1rem 1rem 2rem;
}

.production_container__d0W4J {
  height: 100%;
  padding: 2.25rem 1rem 1rem 2rem;
}

.production_heading__5PEWv {
  font-size: var(--sizing-font-medium);
  font-weight: bold;
}

.production_horizontalGroup__Kbmm3 {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  align-items: center;
  align-items: start;
  margin-top: 0.5rem;
}

.production_label__0aIGw {
  --sizing-gap: 2rem;
  display: block;
  margin-top: var(--sizing-gap);
  color: var(--color-text-4);
}

.production_form__9L9OY {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.akva-content-modal_container__Az55W {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(var(--color-black-rgb), 0.8);
  z-index: calc(var(--zIndex-top) - 1);
  display: grid;
  place-content: center;
}

.akva-content-modal_content__XfKGI {
  width: 90vw;
  height: 90vh;
}

.context-menu_contextMenuButton__OflFN {
  z-index: 0;
  background: none;
  border: none;
  display: grid;
  place-content: center;
  cursor: pointer;
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
}
.context-menu_contextMenuButton__OflFN:hover, .context-menu_listLink__dvcrW:hover {
  background-color: rgba(var(--color-white-rgb), 0.06);
}
.context-menu_contextMenuButton__OflFN:hover svg *, .context-menu_listLink__dvcrW:hover svg * {
  fill: var(--color-text-2);
}

.context-menu_contextMenuButton__OflFN:active, .context-menu_listLink__dvcrW:active {
  background-color: rgba(var(--color-white-rgb), 0.12);
}
.context-menu_contextMenuButton__OflFN[data-is-open='true'], .context-menu_listLink__dvcrW[data-is-open='true'] {
  background-color: rgba(var(--color-white-rgb), 0.12);
}
/* Prevent SVGs from not opening the context menu. */
.context-menu_contextMenuButton__OflFN svg, .context-menu_listLink__dvcrW svg {
  pointer-events: none;
}

.context-menu_contextMenu__VHBba {
  z-index: var(--zIndex-top);
  min-width: 10rem;
}

.context-menu_contextMenuArrow__2r96i,
.context-menu_contextMenuArrow__2r96i::before {
  --arrow-size: 0.75rem;

  position: absolute;
  width: var(--arrow-size);
  height: var(--arrow-size);
  background: var(--color-neutral-2);
  visibility: hidden;
  /* To hide other side of arrow in case of hover */
  z-index: -1;
}

.context-menu_contextMenuArrow__2r96i::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.context-menu_contextMenu__VHBba[data-popper-placement^='top'] > .context-menu_contextMenuArrow__2r96i {
  bottom: calc(var(--arrow-size) / 2 * -1);
}

.context-menu_contextMenu__VHBba[data-popper-placement^='bottom'] > .context-menu_contextMenuArrow__2r96i {
  top: calc(var(--arrow-size) / 2 * -1);
}

.context-menu_contextMenu__VHBba[data-popper-placement^='left'] > .context-menu_contextMenuArrow__2r96i {
  right: calc(var(--arrow-size) / 2 * -1);
}

.context-menu_contextMenu__VHBba[data-popper-placement^='right'] > .context-menu_contextMenuArrow__2r96i {
  left: calc(var(--arrow-size) / 2 * -1);
}

.context-menu_contextMenuList__uRelt {
  list-style-type: none;
  padding: 0.25rem;
  background-color: var(--color-neutral-2);
  border-radius: 4px;
}

.context-menu_contextMenuList__uRelt button,
.context-menu_contextMenuList__uRelt a {
  padding: 0.75rem 1rem;
  cursor: pointer;
  height: 2.5rem;
  width: 12.5rem;
  transition: color 200ms;
}

.context-menu_contextMenuList__uRelt a:disabled,
.context-menu_contextMenuList__uRelt button:disabled {
  background-color: var(--color-neutral-5);
  cursor: not-allowed;
  color: var(--color-text-4);
}

.context-menu_contextMenuList__uRelt span {
  margin-left: 0.75rem;
  white-space: nowrap;
}

.context-menu_contextMenuContainer__EQEtT{
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.popover_popover__P81UJ {
  background-color: var(--color-neutral-1);
  z-index: var(--zIndex-top);
  padding: 1rem;
  border-radius: 0.25rem;
}

.popover_popoverArrow__5fvEr {
  --arrow-size: 8px;
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  border-color: var(--color-neutral-1);
}
.popover_popoverArrow__5fvEr[data-placement^='bottom'] {
  border-width: 0 var(--arrow-size) var(--arrow-size) var(--arrow-size);
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  top: calc(var(--arrow-size) * -1);
  left: calc(50% - var(--arrow-size));
  margin-top: 0;
  margin-bottom: 0;
}
.popover_popoverArrow__5fvEr[data-placement^='top'] {
  border-width: var(--arrow-size) var(--arrow-size) 0 var(--arrow-size);
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  top: 100%;
  left: calc(50% - var(--arrow-size));
  margin-top: 0;
  margin-bottom: 0;
}
.popover_popoverArrow__5fvEr[data-placement^='right'] {
  border-width: var(--arrow-size) var(--arrow-size) var(--arrow-size) 0;
  border-left-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  left: calc(var(--arrow-size) * -1);
  top: calc(50% - var(--arrow-size));
  margin-left: 0;
  margin-right: 0;
}
.popover_popoverArrow__5fvEr[data-placement^='left'] {
  border-width: var(--arrow-size) 0 var(--arrow-size) var(--arrow-size);
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  right: calc(var(--arrow-size) * -1);
  top: calc(50% - var(--arrow-size));
  margin-left: 0;
  margin-right: 0;
}

/* latin-ext */
@font-face {
  font-family: '__DM_Sans_4b5a0e';
  font-style: italic;
  font-weight: 100 1000;
  font-display: swap;
  src: url(/_next/static/media/bcdb820d2d1f1b99-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_4b5a0e';
  font-style: italic;
  font-weight: 100 1000;
  font-display: swap;
  src: url(/_next/static/media/f67bf89ff1d156f0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_4b5a0e';
  font-style: normal;
  font-weight: 100 1000;
  font-display: swap;
  src: url(/_next/static/media/adde053269573569-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_4b5a0e';
  font-style: normal;
  font-weight: 100 1000;
  font-display: swap;
  src: url(/_next/static/media/3e3cf2ee9aa3f174-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_4b5a0e';src: local("Arial");ascent-override: 94.37%;descent-override: 29.49%;line-gap-override: 0.00%;size-adjust: 105.12%
}.__className_4b5a0e {font-family: '__DM_Sans_4b5a0e', '__DM_Sans_Fallback_4b5a0e', Roboto, Helvetica, sans-serif
}

.app-launcher_button__h_Z1P {
  background: none;
  border: none;
  padding: 0 var(--sizing-small);
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 2.5rem;
  border-radius: 0.25rem;
}

.app-launcher_button__h_Z1P:hover {
  background-color: var(--color-neutral-4);
}

.app-launcher_button__h_Z1P:hover svg * {
  fill: var(--color-text-2);
}

.app-launcher_button__h_Z1P:active,
.app-launcher_button__h_Z1P[data-active='true'] {
  background-color: var(--color-neutral-5);
}

.app-launcher_button__h_Z1P:active svg *,
.app-launcher_button__h_Z1P[data-active='true'] svg * {
  fill: var(--color-primary-2);
}

.app-launcher_headerText__h3RCY {
  color: var(--color-text-4);
  font-weight: bold;
  font-size: var(--sizing-font-small);
  width: 25rem;
}

.app-launcher_list__lY007 {
  width: 100%;
  height: 100%;
  list-style-type: none;
  margin-top: 0.5rem;
}

.app-launcher_list__lY007 li:not(:last-of-type) {
  margin-bottom: 0.5rem;
}

.app-launcher_listItem__vDjj2 {
  width: 25rem;
  padding: 1rem;
  background-color: var(--color-neutral-3);
  border-radius: 0.25rem;
}

.app-launcher_content__lXfcd {
  width: 24rem;
  /* Countering the padding of Popover */
  margin: -1rem;
}

.app-launcher_content__lXfcd section {
  padding: 1rem;
  overflow: hidden;
}

.app-launcher_auxiliaryAppsHeader__8tUHY,
.app-launcher_mainAppsHeader__zuCqp {
  color: var(--color-text-4);
  font-size: var(--sizing-font-small);
  font-weight: normal;
  margin-bottom: 0.51rem;
}

.app-launcher_auxiliaryApp__opeDZ {
  display: grid;
  grid-template-columns: 2rem 1fr 1.5rem;
  color: var(--color-text-2);
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  margin: 0 -1rem;
  padding: 1rem;
}

.app-launcher_auxiliaryApp__opeDZ:last-child {
  margin-bottom: -1rem;
}

.app-launcher_auxiliaryAppTitleAndDescription__i5j_U h1 {
  font-size: var(--sizing-font-normal);
}

.app-launcher_auxiliaryAppTitleAndDescription__i5j_U p {
  font-size: var(--sizing-font-small);
  color: var(--color-text-4);
}

.app-launcher_auxiliaryApp__opeDZ:hover {
  background-color: var(--color-neutral-4);
}

.app-launcher_mainAppsGrid__2hG8V {
  /* The width of the parent minus padding and gaps, divided by 4 */
  --width: 4.75rem;

  display: grid;
  grid-template-columns: repeat(auto-fill, var(--width));
  grid-gap: 1rem;
  gap: 1rem;
  padding-top: 1rem;
}

.app-launcher_mainApp__KOxPx {
  display: grid;
  grid-auto-flow: row;
  color: var(--color-text-2);
  justify-items: center;
  align-content: start;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: var(--width);
}

.app-launcher_mainApp__KOxPx h1 {
  font-size: 0.875rem;
  text-align: center;
  width: var(--width);
}

.header_header__91UTb {
  display: flex;
  align-items: center;
  background: var(--color-neutral-1);
  width: 100%;
  height: var(--header-size);
  min-height: var(--header-size);
}

.header_logoContainer__dRfM7 {
  padding-left: var(--sizing-medium);
  display: flex;
  align-items: center;
  height: 100%;
}

.header_logoLink__DFlHx {
  text-decoration: none;
  color: inherit;
  height: 100%;
  display: flex;
}

.header_logoContainer__dRfM7 h1 {
  font-size: var(--sizing-font-normal);
  line-height: var(--sizing-large);
  font-weight: normal;
}

.header_headerText__h2zLw {
  font-weight: bold;
}

.header_logoContainer__dRfM7 img {
  width: 1.5rem;
  height: auto;
  display: inline-block;
  margin-right: var(--sizing-medium);
}

.header_betaBanner__Tr2_c {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-tertiary);
  margin: 0 0.75rem;
  padding: 0.125rem 0.5rem;
  border-radius: 100px;
  font-size: var(--sizing-font-small);
  color: var(--color-text-4);
}

@media only screen and (max-width: 600px) {
  .header_header__91UTb {
    z-index: var(--zIndex-header);
    width: 100%;
  }

  .header_logoContainer__dRfM7 {
    display: none;
  }

  .header_logoContainer__dRfM7 img {
    max-width: 2.5rem;
    width: 100%;
    display: block;
  }
}

.header_organizationName__FFK_6 {
  padding: 0 var(--sizing-medium);
  color: var(--color-text-4);
  display: flex;
  align-items: center;
  height: 100%;
  font-size: var(--sizing-font-small);
  margin-left: auto;
}

.header_divider__5RRin {
  width: 1px;
  height: 1.5rem;
  margin-right: 0.75rem;
  background: var(--color-neutral-6);
  border-radius: 2px;
}

.header_buttonBar__gu_3s {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  height: 100%;
  padding-right: 0.25rem;
}
.header_buttonBar__gu_3s svg {
  pointer-events: none;
}

.language-picker_languagePickerButtonText__EfxYi {
  max-width: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
}

.language-picker_buttonContainer__LTtGY {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.language-picker_languageContainer__INwQ0 {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  padding: 0 0.75rem 0.75rem;
  color: var(--color-text-4);
  font-size: var(--sizing-font-small);
}

.user-settings-menu_icon__inPmw {
  display: block;
}

.user-settings-menu_iconPath___t_0b {
  fill: currentColor;
}

.user-settings-menu_buttonContainer__BSulO {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-settings-menu_button__NIq6_ {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: none;
  cursor: pointer;
}

.user-settings-menu_appSettingsButton__1pVDk,
.user-settings-menu_userSettingsButton__YOial {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: none;
  cursor: pointer;
  border-radius: 0.25rem;
  font-weight: bold;
}

/* Background for hover */
.user-settings-menu_userSettingsButton__YOial:hover {
  background-color: var(--color-neutral-4);
}

/* Background if isOpen true or button being pressed*/
.user-settings-menu_userSettingsButton__YOial:active,
.user-settings-menu_userSettingsButton__YOial[data-active='true'] {
  background-color: var(--color-neutral-5);
}

/* Button color on hover while the button is isOpen false */
.user-settings-menu_userSettingsButton__YOial[data-active='false']:hover .user-settings-menu_buttonBackground__BAAk6 {
  background-color: var(--color-text-2);
}

/* Button color if the button is being pressed or isOpen true */
.user-settings-menu_userSettingsButton__YOial[data-active='true'] .user-settings-menu_buttonBackground__BAAk6,
.user-settings-menu_userSettingsButton__YOial[data-active='false']:active .user-settings-menu_buttonBackground__BAAk6 {
  background-color: var(--color-primary-1);
}

.user-settings-menu_appSettingsButton__1pVDk {
  padding: 0 var(--sizing-normal);
}

.user-settings-menu_buttonBackground__BAAk6 {
  --background-size: 1.5rem;
  width: var(--background-size);
  height: var(--background-size);
  padding: var(--sizing-small);
  background-color: var(--color-tertiary);
  border: 0;
  border-radius: 50%;
  color: var(--color-neutral-1);
  font-size: 0.75rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.user-settings-menu_list__ajgTl {
  list-style-type: none;
  /* Setting a negative margin to counter the parent's(popover) padding. */
  margin: -1rem;
}

.user-settings-menu_list__ajgTl > li {
  white-space: nowrap;
}

.user-settings-menu_list__ajgTl p {
  padding: 0.5rem
}

.user-settings-menu_menuLink__Uohlp {
  margin: 0rem 0.25rem 0.25rem 0.25rem;
}

.user-settings-menu_menuLink__Uohlp button {
  width: 100%;
  background-color: var(--color-neutral-1);   
}

.user-settings-menu_logoutButtonIcon__mtb0x {
  margin-top: 0.5rem;
}

.user-settings-menu_userInfo__QoTXq {
  display: flex;
  align-items: center;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--color-neutral-6);
}
.user-settings-menu_userInfoListItem__HGpLS {
  padding: 0.75rem;
}

.user-settings-menu_userIconBackground__zmWb7 {
  --background-size: 4rem;

  margin: 0;
  font-size: var(--sizing-font-large);
  font-weight: bold;
  margin-right: 0.75rem;
}

.user-settings-menu_userInfoText__vEu2j {
  display: flex;
  flex-direction: column;
  font-size: var(--sizing-font-small);
  font-weight: bold;
}

.user-settings-menu_userInfoTextEmail__GomOn {
  color: var(--color-text-4);
  font-size: var(--sizing-font-xsmall);
  font-weight: normal;
}

.user-settings-menu_appSettingsItem__KsXyj {
  margin: -1rem;
  padding: 1rem;
  color: var(--color-text-2);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-settings-menu_appSettingsItem__KsXyj:hover {
  background-color: var(--color-neutral-4);
}

.user-settings-menu_appSettingsItemColumn__NsT8_ {
  margin-left: var(--sizing-small);
}

.user-settings-menu_appSettingsItemColumn__NsT8_ > p:first-child {
  font-weight: bold;
}

.user-settings-menu_appSettingsItemColumn__NsT8_ > p:last-child {
  color: var(--color-text-4);
  font-size: var(--sizing-font-small);
  line-height: 1rem;
}

.main-layout_mainLayout__2EckD {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.main-layout_mainLayout__columns__icTrz {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
}

.main-layout_content__iOBOn {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  height: 100%;
  width: 100%;
}

.main-layout_spacer__L61qA {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: var(--sidebar-width);
  overflow: hidden;
}

.content-header_contentHeader__knVkU {
  padding: 2rem 2rem 0;
  height: 6rem;
  max-height: 6rem;
}

.content-header_contentHeader__knVkU h2 {
  font-size: var(--sizing-font-medium);
  font-weight: bold;
}

.content-header_infoBadges__Kqmbm {
  display: flex;
  justify-self: end;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 0.25rem 0.5rem;
  margin-left: 2rem;
}
.content-header_infoBadges__Kqmbm dd {
  font-weight: bold;
}
.content-header_infoBadges__Kqmbm dt {
  color: var(--color-text-4);
}

.content-header_infoBadge__JlgMS {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: var(--color-neutral-2);
  padding: 0.125rem 1rem;
  border-radius: 2rem;
}

.side-menu_container__6ln6l {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - var(--header-size));
  background-color: var(--color-neutral-2);
  padding: 0.75rem 0.25rem;
  overflow: hidden;
  position: fixed;
  z-index: 99;
  min-width: 3rem;
}

.side-menu_container__6ln6l:hover {
  overflow-y: auto;
}

.side-menu_container__6ln6l ul {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  list-style: none;
  margin: 2rem 0;
  width: 100%;
  position: relative;
}

.side-menu_container__6ln6l ul::before {
  content: attr(data-title);
  position: absolute;
  top: 0;
  transform: translate(0.5rem, calc(-100% - 0.25rem));
  color: var(--color-text-4);
  opacity: 0;
  transition: opacity 250ms;
}

.side-menu_container__6ln6l[data-open='true'] ul::before {
  opacity: 1;
}

.side-menu_divider__Wh9yE {
  width: calc(100% - 1rem);
  height: 1px;
  background-color: var(--color-neutral-6);
  border-radius: 2px;
}

.side-menu_item__5ll6g {
  --text-color: var(--color-text-4);
  --icon-color: var(--color-tertiary);
  width: 100%;
  background: none;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.side-menu_item__5ll6g button {
  font-weight: bold;
  color: var(--text-color);
  gap: 1rem;
  align-items: center;
  text-overflow: ellipsis;
  min-width: 100%;
  width: 13.5rem;
  transition: 0s !important;
  margin-bottom: 0;
}

.side-menu_item__5ll6g:hover {
  background-color: var(--color-neutral-4);
  --text-color: var(--color-text-2);
  --icon-color: var(--color-text-2);
}
.side-menu_item__5ll6g path,
.side-menu_item__5ll6g circle,
.side-menu_item__5ll6g rect {
  fill: var(--icon-color);
}

.side-menu_item__5ll6g[data-active='true'] {
  background-color: var(--color-neutral-5);
  --text-color: var(--color-primary-2);
  --icon-color: var(--color-primary-2);
}

.side-menu_itemText__Pa0WO {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: inherit;
}

.side-menu_back__FJb_b {
  margin-top: auto;
  font-weight: bold;
  color: var(--color-text-4);
  display: grid;
  grid-template-columns: 1.5rem auto;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem;
}

@font-face {
ascent-override: 90%;
font-family: '__dinot_f1bd0a';
src: url(/_next/static/media/593dfae8c3e60a91-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__dinot_Fallback_f1bd0a';src: local("Arial");ascent-override: 75.53%;descent-override: 23.33%;line-gap-override: 28.47%;size-adjust: 101.15%
}.__className_f1bd0a {font-family: '__dinot_f1bd0a', '__dinot_Fallback_f1bd0a', DM Sans, Roboto, Helvetica, sans-serif;font-weight: 400;font-style: normal
}

