.container {
  color: var(--color-text-2);
  display: inline-block;
  position: relative;
  padding: 0;
  text-align: left;
  height: 2rem;
  margin-bottom: 0.5rem;
}

.icon {
  display: flex;
  justify-content: center;
}
.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  margin-left: 0.75rem;
}
.iconButton:hover {
  background-color: rgba(var(--color-white-rgb), 0.06);
}
.button {
  background: var(--color-neutral-2);

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.25rem 0.375rem 0.25rem 0.5rem;
  outline: none;
  transition: all 200ms;
  border-radius: 0.25rem;
  border: 1px solid var(--color-neutral-6);
  width: 100%;
  height: 2rem;
}

.button[data-active='false']:enabled:hover {
  cursor: pointer;
  border-color: var(--color-neutral-7);
}

.button[data-active='true'] {
  border: 1px solid var(--color-primary-1);
  color: var(--color-text-2);
}

.labelText {
  height: 100%;
  text-align: left;
  font-size: var(--sizing-font-normal);
  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Added this */
  max-width: 100%; /* Ensures it takes up to 100% of parent width */

  color: var(--color-text-2);

  /* Disabling pointer-events for the checkbox component, because
  otherwise onStateChange event gets triggered twice and as a 
  result selected items always remain unselected. */
  pointer-events: none;
}

.labelText[data-disabled='true'] {
  color: var(--color-text-5);
}

.dropdown {
  border-radius: 0.25rem;
  background: var(--color-neutral-2);

  list-style-type: none;

  overflow-y: auto;
  overflow-x: hidden;
  min-width: max(100%, 12rem);
  max-width: 20rem;

  max-height: 55vh;

  /* Use really high zIndex to ensure on top */
  z-index: 9999;
  padding-top: 0.25rem;
  margin-top: 2px;
}
.dropdown ul {
  min-width: 100%;
}

.item {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0.25rem 0.25rem;
  border-radius: 0.25rem;
  flex-direction: column;
}

.departmentRow {
  display: flex;
  flex-direction: column;

  /* Gap between each row */
  margin-bottom: 0.25rem;
  border-radius: 4px;
  padding: 0.5rem;
}
.departmentRow[data-is-disabled='false']:hover,
.departmentRow[data-highlighted-index='true'] {
  background-color: var(--color-neutral-4);
}

.departmentContent {
  font-size: 1rem;
  align-items: center;
  justify-items: center;
  display: flex;
  gap: 0.75rem;
}

/* Changing the color of the checkbox border on hovering the department row */
.departmentRow[data-expand-hovered='false']:hover div > label > span {
  border-color: var(--color-text-4);
  transition: border-color 150ms;
  pointer-events: none;
}

.departmentButton {
  display: flex;

  align-items: center;
  justify-content: space-between;

  font-size: 1rem;
  width: 100%;
  height: 100%;
  font-weight: normal;
}
.departmentButton span {
  justify-self: start;
}

.departmentLabel p {
  transition: color 250ms;
}
.departmentLabel *:first-child {
  color: var(--color-text-2);
  text-align: left;
}
.departmentLabel[data-is-disabled='true'] p,
.departmentVolumeLabel[data-is-disabled='true'] {
  color: var(--color-text-5);
}

.departmentVolume {
  margin-left: auto;
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.departmentVolumeLabel {
  font-size: var(--sizing-font-xsmall);
  color: var(--color-text-4);
}
.unitsList {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.unitRow {
  display: grid;
  grid-template-columns: 1.5rem 1fr max-content;
  gap: 0.75rem;
  align-items: center;

  padding: 8px 8px 8px 32px;
  border-radius: 4px;
  cursor: pointer;
}
.unitRow:hover {
  background-color: var(--color-neutral-4);
}

/* Changing the color of the checkbox border on hovering the unit row */
.unitRow:hover label > span {
  border-color: var(--color-text-4);
}

.unit span {
  transition: color 250ms;
}
.unitVolume {
  font-size: var(--sizing-font-small);
  color: var(--color-text-4);
}
.unitDisabled span {
  color: var(--color-text-5);
}

/* Clicking on the checkbox directly closes the dropdown, 
   so disabling any pointer events within it to avoid 
   that */
.departmentRow div > label,
.unitRow label {
  pointer-events: none;
}

.departmentButton[data-is-disabled='true'],
.departmentButton[data-is-disabled='true'] *,
.unitDisabled {
  color: var(--color-text-5);
  cursor: default;
}

.departmentRow[data-is-disabled='true'] {
  cursor: default;
}
