.container {
  height: 100%;
  padding: 2.25rem 1rem 1rem 2rem;
}

.heading {
  font-size: var(--sizing-font-medium);
  font-weight: bold;
}

.horizontalGroup {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: 1.5rem;
  align-items: center;
  align-items: start;
  margin-top: 0.5rem;
}

.label {
  --sizing-gap: 2rem;
  display: block;
  margin-top: var(--sizing-gap);
  color: var(--color-text-4);
}

.form {
  height: 100%;
  display: flex;
  flex-direction: column;
}
