.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.formInputs {
  max-width: 24rem;
  display: flex;
  flex-direction: column;
  gap: var(--sizing-medium);
}

.formInputs label {
  min-width: 8rem;
}

.formSelect {
  display: flex;
  gap: var(--sizing-tiny);
}

.formSelect > div {
  width: 100%;
}
