.contextMenuButton {
  z-index: 0;
  background: none;
  border: none;
  display: grid;
  place-content: center;
  cursor: pointer;
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
}
.contextMenuButton:hover, .listLink:hover {
  background-color: rgba(var(--color-white-rgb), 0.06);
}
.contextMenuButton:hover svg *, .listLink:hover svg * {
  fill: var(--color-text-2);
}

.contextMenuButton:active, .listLink:active {
  background-color: rgba(var(--color-white-rgb), 0.12);
}
.contextMenuButton[data-is-open='true'], .listLink[data-is-open='true'] {
  background-color: rgba(var(--color-white-rgb), 0.12);
}
/* Prevent SVGs from not opening the context menu. */
.contextMenuButton svg, .listLink svg {
  pointer-events: none;
}

.contextMenu {
  z-index: var(--zIndex-top);
  min-width: 10rem;
}

.contextMenuArrow,
.contextMenuArrow::before {
  --arrow-size: 0.75rem;

  position: absolute;
  width: var(--arrow-size);
  height: var(--arrow-size);
  background: var(--color-neutral-2);
  visibility: hidden;
  /* To hide other side of arrow in case of hover */
  z-index: -1;
}

.contextMenuArrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.contextMenu[data-popper-placement^='top'] > .contextMenuArrow {
  bottom: calc(var(--arrow-size) / 2 * -1);
}

.contextMenu[data-popper-placement^='bottom'] > .contextMenuArrow {
  top: calc(var(--arrow-size) / 2 * -1);
}

.contextMenu[data-popper-placement^='left'] > .contextMenuArrow {
  right: calc(var(--arrow-size) / 2 * -1);
}

.contextMenu[data-popper-placement^='right'] > .contextMenuArrow {
  left: calc(var(--arrow-size) / 2 * -1);
}

.contextMenuList {
  list-style-type: none;
  padding: 0.25rem;
  background-color: var(--color-neutral-2);
  border-radius: 4px;
}

.contextMenuList button,
.contextMenuList a {
  padding: 0.75rem 1rem;
  cursor: pointer;
  height: 2.5rem;
  width: 12.5rem;
  transition: color 200ms;
}

.contextMenuList a:disabled,
.contextMenuList button:disabled {
  background-color: var(--color-neutral-5);
  cursor: not-allowed;
  color: var(--color-text-4);
}

.contextMenuList span {
  margin-left: 0.75rem;
  white-space: nowrap;
}

.contextMenuContainer{
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
