.selectLabel {
  padding-bottom: 0.25rem;
  display: inline-block;
  color: var(--color-text-4);
  font-size: var(--font-sizing-small);
}

.stepsContainer {
  height: 100%;
  width: 100%;
  padding: 1rem;
  position: relative;
}

.stepBarContainer {
  --step-card-width: 15rem;
  --icon-size: 2.5rem;
  --box-width: 16.5rem;
  --nav-bar-height: 3rem;
  --gap-between-step-boxes: 1rem;

  display: flex;
  width: fit-content;
  align-items: center;

  margin-top: 3rem;
  margin-left: calc(
    1rem + calc(var(--step-card-width) / 2) - calc(var(--icon-size) / 2)
  );

  transition: width 250ms;
  height: var(--nav-bar-height);

  padding-right: 8rem;
}

.logisticsStep[data-is-valid='false'] form > div {
  border: 1px solid red;
}

.stepButtonsRow > button {
  width: 100%;
}
