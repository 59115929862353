.mainLayout {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.mainLayout__columns {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
}

.content {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  height: 100%;
  width: 100%;
}

.spacer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: var(--sidebar-width);
  overflow: hidden;
}
