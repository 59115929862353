.informationRow {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  text-align: right;
}

.label {
  color: var(--color-text-4);
  font-size: 14px;
}
.value {
  color: var(--color-text-2);
  font-size: 14px;
  font-weight: bold;
  cursor: text;
  pointer-events: none;
}
