.icon {
  display: block;
}

.iconPath {
  fill: currentColor;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: none;
  cursor: pointer;
}

.appSettingsButton,
.userSettingsButton {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: none;
  cursor: pointer;
  border-radius: 0.25rem;
  font-weight: bold;
}

/* Background for hover */
.userSettingsButton:hover {
  background-color: var(--color-neutral-4);
}

/* Background if isOpen true or button being pressed*/
.userSettingsButton:active,
.userSettingsButton[data-active='true'] {
  background-color: var(--color-neutral-5);
}

/* Button color on hover while the button is isOpen false */
.userSettingsButton[data-active='false']:hover .buttonBackground {
  background-color: var(--color-text-2);
}

/* Button color if the button is being pressed or isOpen true */
.userSettingsButton[data-active='true'] .buttonBackground,
.userSettingsButton[data-active='false']:active .buttonBackground {
  background-color: var(--color-primary-1);
}

.appSettingsButton {
  padding: 0 var(--sizing-normal);
}

.buttonBackground {
  --background-size: 1.5rem;
  width: var(--background-size);
  height: var(--background-size);
  padding: var(--sizing-small);
  background-color: var(--color-tertiary);
  border: 0;
  border-radius: 50%;
  color: var(--color-neutral-1);
  font-size: 0.75rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.list {
  list-style-type: none;
  /* Setting a negative margin to counter the parent's(popover) padding. */
  margin: -1rem;
}

.list > li {
  white-space: nowrap;
}

.list p {
  padding: 0.5rem
}

.menuLink {
  margin: 0rem 0.25rem 0.25rem 0.25rem;
}

.menuLink button {
  width: 100%;
  background-color: var(--color-neutral-1);   
}

.logoutButtonIcon {
  margin-top: 0.5rem;
}

.userInfo {
  display: flex;
  align-items: center;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--color-neutral-6);
}
.userInfoListItem {
  padding: 0.75rem;
}

.userIconBackground {
  composes: buttonBackground;
  --background-size: 4rem;

  margin: 0;
  font-size: var(--sizing-font-large);
  font-weight: bold;
  margin-right: 0.75rem;
}

.userInfoText {
  display: flex;
  flex-direction: column;
  font-size: var(--sizing-font-small);
  font-weight: bold;
}

.userInfoTextEmail {
  color: var(--color-text-4);
  font-size: var(--sizing-font-xsmall);
  font-weight: normal;
}

.appSettingsItem {
  margin: -1rem;
  padding: 1rem;
  color: var(--color-text-2);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.appSettingsItem:hover {
  background-color: var(--color-neutral-4);
}

.appSettingsItemColumn {
  margin-left: var(--sizing-small);
}

.appSettingsItemColumn > p:first-child {
  font-weight: bold;
}

.appSettingsItemColumn > p:last-child {
  color: var(--color-text-4);
  font-size: var(--sizing-font-small);
  line-height: 1rem;
}
