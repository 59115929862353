.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.stepForm {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.label {
  color: var(--color-text-4);
  font-size: 14px;
}

.textField {
  display: flex;
  gap: 0.75rem;
  width: 100%;
  min-height: 2rem;
  margin-bottom: 0.75rem;
}

.departmentsMultiselect {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

/* Todo: Remove minimum width property from the library */

.departmentsMultiselect > div > div > button {
  width: 100% !important;
  min-width: none;
}

.stepName {
  --step-label-width: 6rem;
  font-size: var(--sizing-font-normal);
  color: var(--color-text-2);
  font-weight: bold;
  cursor: pointer;
  left: calc(var(--icon-size) / 2 - var(--step-label-width) / 2);
  width: var(--step-label-width);
  text-align: center;
  position: absolute;
  padding: 0.5rem;
  bottom: -2.75rem;
}


.parallelName {
  font-size: var(--sizing-font-small);
  color: var(--color-text-2);
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 1.5rem;
}
.parallelName[data-is-active='true'] {
  height: 2rem; /*  Because of the icon height */
}

.buttonSpan > button {
  width: 100%;
}

.parallelPreview {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 4px;
  background: rgba(var(--color-white-rgb), 0.06);
  border-radius: 0.25rem;
  width: 100%;
}

.parallelPreview[data-is-active='true'] {
  gap: 8px;
}

.buttonsContainer {
  padding: 1rem 1rem 1rem 2rem;
}
