.sidebar {
  --circle-radius: 8px;
  --line-width: 2px;
  --line-height: 2rem;
  --border-color: var(--color-neutral-7);
  --border-color-active: var(--color-primary-1);
  --background-color: var(--color-primary-1);
  --padding-y: 2rem;
  --padding-x: 1rem;
  --line-margin: 2px;
  --spacing: 1.25rem;
  --steps-gap: 5rem;

  background-color: var(--color-neutral-2);
  padding: 2rem 1rem 1rem;

  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto max-content max-content;
  grid-auto-flow: row;
  word-break: break-all;
  min-width: var(--sidebar-width);
}

.sidebar ul {
  list-style: none;
}

.sidebar li {
  white-space: nowrap;
}

.sections {
  display: flex;
  flex-direction: column;
  gap: var(--steps-gap);
}

.sections li {
  position: relative;
  margin: 0;
  height: 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.sections li[data-visited='false'] {
  color: var(--color-text-5);
}
.sections li[data-active='true'] {
  font-weight: bold;
  color: var(--color-text-2);
}

.sections li[data-visited='true'] {
  cursor: pointer;
  color: var(--color-text-4);
}

.sections li[data-completed='true']:hover {
  color: var(--color-text-2);
}

/* The before element is the line connecting the circles. */
.sections li::before {
  background-color: var(--border-color);
  width: var(--line-width);
  border-radius: var(--line-width);
  height: var(--steps-gap);
  left: 0;
  top: 0;
  content: '';
  position: absolute;
  transform: translate(calc(var(--circle-radius) - 50%), calc(var(--spacing)));
}
.sections li:last-child::before {
  display: none;
}

.sidebar li[data-completed='true']::before {
  background-color: var(--border-color-active);
}

.sectionsCircle {
  border-radius: 100%;
  background-color: transparent;
  border: var(--line-width) solid var(--border-color);
  width: calc(2 * var(--circle-radius));
  height: calc(2 * var(--circle-radius));
  display: grid;
  place-content: center;
  box-sizing: border-box;
}

.sections li[data-completed='true'] .sectionsCircle {
  background-color: var(--background-color);
  border-color: var(--border-color-active);
}

.sections li[data-active='true'][data-completed='false'] .sectionsCircle {
  background-color: transparent;
  border-color: var(--border-color-active);
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.sidebarInfo {
  display: flex;
  flex-direction: column;
  gap: var(--sizing-tiny);
}

.sidebarInfo dt {
  color: var(--color-text-4);
  font-size: var(--sizing-font-small);
}

.sidebarInfo dd {
  color: var(--color-text-2);
  font-size: var(--sizing-font-normal);
  word-break: break-word;
}

.cancelButton {
  width: 100%;
}
