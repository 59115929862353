.header {
  display: flex;
  align-items: center;
  background: var(--color-neutral-1);
  width: 100%;
  height: var(--header-size);
  min-height: var(--header-size);
}

.logoContainer {
  padding-left: var(--sizing-medium);
  display: flex;
  align-items: center;
  height: 100%;
}

.logoLink {
  text-decoration: none;
  color: inherit;
  height: 100%;
  display: flex;
}

.logoContainer h1 {
  font-size: var(--sizing-font-normal);
  line-height: var(--sizing-large);
  font-weight: normal;
}

.headerText {
  font-weight: bold;
}

.logoContainer img {
  width: 1.5rem;
  height: auto;
  display: inline-block;
  margin-right: var(--sizing-medium);
}

.betaBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-tertiary);
  margin: 0 0.75rem;
  padding: 0.125rem 0.5rem;
  border-radius: 100px;
  font-size: var(--sizing-font-small);
  color: var(--color-text-4);
}

@media only screen and (max-width: 600px) {
  .header {
    z-index: var(--zIndex-header);
    width: 100%;
  }

  .logoContainer {
    display: none;
  }

  .logoContainer img {
    max-width: 2.5rem;
    width: 100%;
    display: block;
  }
}

.organizationName {
  padding: 0 var(--sizing-medium);
  color: var(--color-text-4);
  display: flex;
  align-items: center;
  height: 100%;
  font-size: var(--sizing-font-small);
  margin-left: auto;
}

.divider {
  width: 1px;
  height: 1.5rem;
  margin-right: 0.75rem;
  background: var(--color-neutral-6);
  border-radius: 2px;
}

.buttonBar {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  height: 100%;
  padding-right: 0.25rem;
}
.buttonBar svg {
  pointer-events: none;
}
