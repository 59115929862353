.contentHeader {
  padding: 2rem 2rem 0;
  height: 6rem;
  max-height: 6rem;
}

.contentHeader h2 {
  font-size: var(--sizing-font-medium);
  font-weight: bold;
}

.infoBadges {
  display: flex;
  justify-self: end;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 0.25rem 0.5rem;
  margin-left: 2rem;
}
.infoBadges dd {
  font-weight: bold;
}
.infoBadges dt {
  color: var(--color-text-4);
}

.infoBadge {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: var(--color-neutral-2);
  padding: 0.125rem 1rem;
  border-radius: 2rem;
}
