.errorMessageContainer {
  display: flex;
  height: max-content;
  gap: 0.25rem;
  margin-top: 0.25rem;
}

.errorMessage {
  font-size: var(--sizing-font-small);
  color: var(--color-text-2);
  font-weight: normal;
  height: 100%;
}
