.container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(var(--color-black-rgb), 0.8);
  z-index: calc(var(--zIndex-top) - 1);
  display: grid;
  place-content: center;
}

.content {
  width: 90vw;
  height: 90vh;
}
