.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - var(--header-size));
  background-color: var(--color-neutral-2);
  padding: 0.75rem 0.25rem;
  overflow: hidden;
  position: fixed;
  z-index: 99;
  min-width: 3rem;
}

.container:hover {
  overflow-y: auto;
}

.container ul {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  list-style: none;
  margin: 2rem 0;
  width: 100%;
  position: relative;
}

.container ul::before {
  content: attr(data-title);
  position: absolute;
  top: 0;
  transform: translate(0.5rem, calc(-100% - 0.25rem));
  color: var(--color-text-4);
  opacity: 0;
  transition: opacity 250ms;
}

.container[data-open='true'] ul::before {
  opacity: 1;
}

.divider {
  width: calc(100% - 1rem);
  height: 1px;
  background-color: var(--color-neutral-6);
  border-radius: 2px;
}

.item {
  --text-color: var(--color-text-4);
  --icon-color: var(--color-tertiary);
  width: 100%;
  background: none;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.item button {
  font-weight: bold;
  color: var(--text-color);
  gap: 1rem;
  align-items: center;
  text-overflow: ellipsis;
  min-width: 100%;
  width: 13.5rem;
  transition: 0s !important;
  margin-bottom: 0;
}

.item:hover {
  background-color: var(--color-neutral-4);
  --text-color: var(--color-text-2);
  --icon-color: var(--color-text-2);
}
.item path,
.item circle,
.item rect {
  fill: var(--icon-color);
}

.item[data-active='true'] {
  background-color: var(--color-neutral-5);
  --text-color: var(--color-primary-2);
  --icon-color: var(--color-primary-2);
}

.itemText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: inherit;
}

.back {
  composes: item;
  margin-top: auto;
  font-weight: bold;
  color: var(--color-text-4);
  display: grid;
  grid-template-columns: 1.5rem auto;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem;
}
