.growthModelSelectContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.growthModelSelectContainer > * {
  max-width: 18.75rem;
}

.growthModelSelectContainer button {
  padding: 0.25rem 0.5rem;
}

/* Select the dropdown menu and make it scrollable */
.growthModelSelectContainer div div:last-child {
  max-height: 30rem;
  overflow: auto;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.salesStrategies {
  display: flex;
  gap: 1.5rem;
}

.cardSection {
  --border-color: var(--color-tertiary);
  border: 1px solid var(--border-color);
  background-color: var(--color-neutral-4);
  border-radius: 0.25rem;

  width: 284px;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  cursor: pointer;
}
.cardSection:hover {
  --border-color: var(--color-text-4);
  cursor: pointer;
}

.cardSection[data-active='true'] {
  --border-color: var(--color-primary-1);
}
.icons {
  display: flex;
  align-items: center;
  margin-top: auto;
}
.icons[data-flex-end='true'] {
  justify-content: flex-end;
}

.cardSection {
  font-size: var(--sizing-font-small);
  line-height: 1.25rem;
}

.label {
  color: var(--color-text-4);
  word-wrap: break-word;
  white-space: normal;
}

.setupContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.setupLabel {
  width: fit-content;
  line-height: 1.25rem;
  font-size: var(--sizing-font-small);
  color: var(--color-text-4);
}
