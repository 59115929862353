.container {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.gradient {
  --color-gradient-rgb: 0, 187, 228;

  background: linear-gradient(
    to bottom,
    rgba(var(--color-gradient-rgb), 0.15) 0%,
    rgba(var(--color-neutral-3-rgb), 1) 100%
  );
  height: 140px;
  width: 100%;
  position: absolute;
  pointer-events: none;
  z-index: -1;
}
.header {
  height: 6rem;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
}

.siteDropdown {
  display: flex;
  gap: var(--sizing-medium);
  height: 2rem;
  align-items: center;
  color: var(--color-text-4);
  font-size: var(--sizing-font-small);
}

.siteDropdown ul {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 50vh;
}

.section {
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: var(--sizing-normal);
}

.sectionTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.h1 {
  font-size: var(--sizing-font-large);
}

.h2 {
  font-size: var(--sizing-font-medium);
  font-weight: bold;
}

.batchName {
  display: flex;
  gap: var(--sizing-medium);
  align-items: center;
}

.batchColor {
  height: var(--sizing-medium);
  width: var(--sizing-medium);
  border-radius: 50%;
  display: inline-block;
  background: var(--dark-graph-graph-3, #00beeb);
  min-width: 0.75rem;
}

.sectionTable {
  min-height: 2rem;
  text-align: left;
  background-color: var(--color-neutral-5);
  border-radius: 0.5rem;
  border-collapse: collapse;
}

/* Last tbody in the first table */
.sectionTable:first-of-type tbody:last-of-type {
  display: block;
  border-radius: 0 0 0.5rem 0.5rem;
}

.sectionTable:not(:first-child) {
  border-bottom: hidden;
}

.sectionTable tbody:not(:last-child) {
  border-bottom: 0.5rem solid transparent;
  border-top: 0.5rem solid transparent;
}


#prodTable tbody:nth-child(even) {  
  background: var(--dark-neutrals-neutral-4, #26323c);    
}

/* row */
.sectionTable tr {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  gap: var(--sizing-large);
  overflow-wrap: break-word;
  overflow: hidden;
  justify-content: flex-end;
}

/* header row */
.sectionTable thead {
  min-height: 2.75rem;
  color: var(--color-text-4);
  font-size: var(--sizing-font-small);
  position: relative;
}

.sectionTable thead::after {
  content: '';
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--color-neutral-6);
}

.sectionTable th {
  font-weight: 400;
}

/* cells */
.sectionTable td,
.sectionTable th {
  line-height: 1rem;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sectionTable td:not(:last-child),
.sectionTable th:not(:last-child) {
  flex-basis: 125px;
}

/* first cell */
.sectionTable td:first-child,
.sectionTable th:first-child {
  min-width: 20%;
  flex-grow: 1;
  gap: 0.75rem;
}

.sectionTable th[data-align='left'],
.sectionTable td[data-align='left'] {
  justify-content: flex-start;
}

.sectionTable th[data-align='center'],
.sectionTable td[data-align='center'] {
  justify-content: center;
}

.sectionTable th[data-align='right'],
.sectionTable td[data-align='right'] {
  justify-content: flex-end;
}

.sectionTable td:last-child,
.sectionTable th:last-child {
  width: 4rem;
}

.sectionTable td[data-type='no-data'] {
  height: 140px;
  justify-content: center;
  color: var(--color-text-5);
}

.sectionTable h3 {
  font-weight: bold;
  color: var(--color-text-2);
  font-size: var(--sizing-font-normal);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

.flexAlignRight {
  justify-content: flex-end;
}

.secondBatchTableBackgroundColor {
  background: var(--dark-neutrals-neutral-4, #26323c);
}

.hidden {
  visibility: hidden;
}

.productionTarget {
  padding-left: 3rem;
}
