.stepSummary {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.textField {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  width: 100%;
  height: 2rem;
}

.stepName {
  font-size: var(--sizing-font-normal);
  color: var(--color-text-2);
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline;
}

.removeStepButton {
  min-width: 2rem;
  height: 2rem;
  border-radius: 0.25rem;
  border: none;
  display: grid;
  place-content: center;
  background-color: transparent;
  cursor: pointer;
}
.removeStepButton:hover {
  background-color: var(--color-secondary-1);
}

.stepInformationContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.25rem
}

.parallelName {
  font-size: var(--sizing-font-small);
  color: var(--color-text-2);
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 1.5rem;
}
.parallelName[data-is-active='true'] {
  height: 2rem; /*  Because of the icon height */
}

.parallelPreview {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 4px;
  background: rgba(var(--color-white-rgb), 0.06);
  border-radius: 0.25rem;
  width: 100%;
}

.parallelPreview[data-is-active='true'] {
  gap: 8px;
}
