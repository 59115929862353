.deliveryContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.deliverySection {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 2rem;
}

.deliveryInputRow {
  display: flex;
  gap: 4rem;
  width: fit-content;
}
.deliveryInputSection {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 280px;
}

.deliveryBody {
  display: flex;
  gap: 2rem;
  overflow-x: auto;
  padding-right: 8rem;
  height: 100%;
  padding-bottom: 1rem;
}
.deliveryBody[data-mode='delivery'] {
  min-height: fit-content;
  margin-bottom: 10px;
}

.deliveryItem {
  flex: 0 0 auto; /* This ensures that the items do not shrink */
  height: fit-content;
}

.salesDelivery {
  margin-top: auto;
  margin-bottom: 2rem;
}
.informationTable {
  border: 1px solid var(--color-neutral-6);
  border-radius: 4px;
  display: flex;
  gap: 1.5rem;
  padding: var(--sizing-normal);
  width: fit-content;
  height: fit-content;
}
.informationCellContainer {
  display: flex;
  gap: 1.5rem;
}

.informationCell {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.verticalDivider {
  width: 1px;
  height: 100%;
  border-radius: 2px;
  background-color: var(--color-neutral-6);
  display: inline-block;
}

.informationValue {
  line-height: 28px;
  font-size: var(--sizing-font-medium);
  color: var(--color-text-2);
  font-weight: bold;
}
.informationLabel {
  line-height: 20px;
  font-size: var(--sizing-font-small);
  color: var(--color-text-4);
}
.calendarLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stepSummary {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
}

.stepHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  width: 100%;
  height: 2rem;
}
.stepNameContainer {
  display: flex;
  gap: 0.5rem;

  align-items: center;
}
.stepNumber {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  color: var(--color-text-2);
  font-size: var(--sizing-font-xsmall);
}

.stepName {
  font-size: var(--sizing-font-normal);
  color: var(--color-text-2);
  font-weight: bold;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline;
}

.removeStepButton {
  min-width: 2rem;
  height: 2rem;
  border-radius: 0.25rem;
  border: none;
  display: grid;
  place-content: center;
  background-color: transparent;
  cursor: pointer;
}
.removeStepButton:hover {
  background-color: var(--color-secondary-1);
}

.wholeBatch {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.wholeBatchAmount {
  color: var(--color-text-4);
}
.wholeBatchAmount[data-disabled='true'] {
  color: var(--color-text-5);
}
