.popover {
  background-color: var(--color-neutral-1);
  z-index: var(--zIndex-top);
  padding: 1rem;
  border-radius: 0.25rem;
}

.popoverArrow {
  --arrow-size: 8px;
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  border-color: var(--color-neutral-1);
}
.popoverArrow[data-placement^='bottom'] {
  border-width: 0 var(--arrow-size) var(--arrow-size) var(--arrow-size);
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  top: calc(var(--arrow-size) * -1);
  left: calc(50% - var(--arrow-size));
  margin-top: 0;
  margin-bottom: 0;
}
.popoverArrow[data-placement^='top'] {
  border-width: var(--arrow-size) var(--arrow-size) 0 var(--arrow-size);
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  top: 100%;
  left: calc(50% - var(--arrow-size));
  margin-top: 0;
  margin-bottom: 0;
}
.popoverArrow[data-placement^='right'] {
  border-width: var(--arrow-size) var(--arrow-size) var(--arrow-size) 0;
  border-left-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  left: calc(var(--arrow-size) * -1);
  top: calc(50% - var(--arrow-size));
  margin-left: 0;
  margin-right: 0;
}
.popoverArrow[data-placement^='left'] {
  border-width: var(--arrow-size) 0 var(--arrow-size) var(--arrow-size);
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  right: calc(var(--arrow-size) * -1);
  top: calc(50% - var(--arrow-size));
  margin-left: 0;
  margin-right: 0;
}
