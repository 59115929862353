.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sortingStepContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.label {
  color: var(--color-text-4);
  font-size: 14px;
}

.stepInformationContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.informationContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

.degreeDaysSummary {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.stepButtonsRow {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.sortingHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sortingBadge {
  height: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 2px 8px;
  border-radius: 100px;
  background-color: var(--color-neutral-1);

  color: var(--color-text-4);
  font-size: var(--sizing-font-small);
}

.sortingPreview {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 4px;
  background: rgba(var(--color-white-rgb), 0.06);
  border-radius: 0.25rem;
  width: 100%;
}
.weightClassName {
  font-size: var(--sizing-font-small);
  color: var(--color-text-2);
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  width: 100%;
  min-height: 2rem;
}

.sortingPreview[data-is-active='true'] {
  gap: 8px;
}

.sortingWeightClasses {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.departmentsMultiselect {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

.keepSortingMultiSelect {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 50%;
}

.buttonSpan > button {
  width: 100%;
}

.sortingRow {
  display: flex;
  width: 100%;
  gap: 4px;
}

.departmentsMultiselect {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.buttonsContainer {
  padding: 1rem 1rem 1rem 2rem;
}
