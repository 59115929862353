.button {
  background: none;
  border: none;
  padding: 0 var(--sizing-small);
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 2.5rem;
  border-radius: 0.25rem;
}

.button:hover {
  background-color: var(--color-neutral-4);
}

.button:hover svg * {
  fill: var(--color-text-2);
}

.button:active,
.button[data-active='true'] {
  background-color: var(--color-neutral-5);
}

.button:active svg *,
.button[data-active='true'] svg * {
  fill: var(--color-primary-2);
}

.headerText {
  color: var(--color-text-4);
  font-weight: bold;
  font-size: var(--sizing-font-small);
  width: 25rem;
}

.list {
  width: 100%;
  height: 100%;
  list-style-type: none;
  margin-top: 0.5rem;
}

.list li:not(:last-of-type) {
  margin-bottom: 0.5rem;
}

.listItem {
  width: 25rem;
  padding: 1rem;
  background-color: var(--color-neutral-3);
  border-radius: 0.25rem;
}

.content {
  width: 24rem;
  /* Countering the padding of Popover */
  margin: -1rem;
}

.content section {
  padding: 1rem;
  overflow: hidden;
}

.auxiliaryAppsHeader,
.mainAppsHeader {
  color: var(--color-text-4);
  font-size: var(--sizing-font-small);
  font-weight: normal;
  margin-bottom: 0.51rem;
}

.auxiliaryApp {
  display: grid;
  grid-template-columns: 2rem 1fr 1.5rem;
  color: var(--color-text-2);
  gap: 0.5rem;
  align-items: center;
  margin: 0 -1rem;
  padding: 1rem;
}

.auxiliaryApp:last-child {
  margin-bottom: -1rem;
}

.auxiliaryAppTitleAndDescription h1 {
  font-size: var(--sizing-font-normal);
}

.auxiliaryAppTitleAndDescription p {
  font-size: var(--sizing-font-small);
  color: var(--color-text-4);
}

.auxiliaryApp:hover {
  background-color: var(--color-neutral-4);
}

.mainAppsGrid {
  /* The width of the parent minus padding and gaps, divided by 4 */
  --width: 4.75rem;

  display: grid;
  grid-template-columns: repeat(auto-fill, var(--width));
  gap: 1rem;
  padding-top: 1rem;
}

.mainApp {
  display: grid;
  grid-auto-flow: row;
  color: var(--color-text-2);
  justify-items: center;
  align-content: start;
  gap: 0.5rem;
  width: var(--width);
}

.mainApp h1 {
  font-size: 0.875rem;
  text-align: center;
  width: var(--width);
}
