.container {
  --sidebar-width: 220px;
  display: flex;
  flex-direction: column;
  border-radius: var(--sizing-border-radius);
  overflow: hidden;
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.header {
  height: 3rem;
  width: 100%;
  background-color: var(--color-neutral-1);
  padding: 0.75rem 1rem;
}
.header label {
  font-weight: bold;
  font-size: var(--sizing-font-normal);
}

.heading {
  font-size: var(--sizing-font-medium);
  font-weight: bold;
  padding: 2rem 2rem 0;
}

.body {
  display: flex;
  background-color: var(--color-neutral-3);
  position: relative;
  flex-grow: 1;
}
.sidebar {
  height: 100%;
  width: var(--sidebar-width);
  z-index: 1;
}

.gradient {
  --color-gradient-rgb: 0, 187, 228;
  background: linear-gradient(
    to bottom,
    rgba(var(--color-gradient-rgb), 0.12) 0%,
    rgba(var(--color-neutral-3-rgb), 1) 70%
  );
  height: 140px;
  width: calc(100%);
  pointer-events: none;
  z-index: 0;
  position: absolute;
}

.section {
  width: 100%;
  overflow-y: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
}
